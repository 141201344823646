.contact-container {
  height: calc(100vh - 170px);
  background-image: url("../../assets/images/contact-bg.jpg");
  background-color: #0096c8;
  background-size: cover;
  padding: 60px 15px;
}

.contact-content-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  height: 85%;
}

.contact-img-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  align-items: start;
  width: 100%;
  text-align: center;
}

.contact-large-print {
  font-size: 30px;
}

.contact-header {
  margin-bottom: 55px;
}

.contact-images {
  width: 150px;
  height: auto;
}

.phone-number {
  text-decoration: none;
  color: white;
}

.contact-bottom {
  color: white;
  font-size: 14pt;
}

@media only screen and (max-width: 1150px) {
  .contact-container {
    height: 100%;
  }
  .contact-img-container {
    grid-template-columns: 1fr;
    padding-bottom: 30px;
  }
  .contact-img-left {
    padding: 30px 0;
  }
  .contact-images {
    width: 100px;
  }
  .contact-large-print {
    font-size: 25px;
  }
  .contact-bottom {
    font-size: 10pt;
    text-align: center;
  }
}

@media (max-height: 700px) {
  .contact-container {
    height: 100%;
  }
}
