.investments-container {
  width: 100%;
  padding: 60px 15px;
  padding-bottom: 5.71429rem !important;
}

.container-padding {
  padding-bottom: 2.14286rem !important;
  padding-top: 2.85714rem !important;
}

.seeking-img {
  margin-bottom: 2.14286rem !important;
}

.seeking-img-titles {
  font-size: 23px;
  line-height: 35px;
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.seeking-info-div {
  max-width: 1600px;
  margin: auto;
}

@media only screen and (max-width: 1199px) {
  .seeking-img {
    margin-bottom: 5px !important;
  }
  .seeking-img-titles {
    font-size: 20px;
    line-height: 35px;
    margin-top: 0.5rem !important;
    margin-bottom: 2.5rem !important;
  }
}
