.hero-container {
  margin-bottom: -6px;
}

#hero_video {
  min-height: 100%;
  max-width: 100%;
}

.hero-content-show-small {
  padding: 30px;
  font-size: 22px;
}

.hero-content-show-small p {
  margin: 0;
}

.hero_content_wrapper {
  padding-bottom: 25px;
  padding-top: 25px;
  padding-right: 30px;
  padding-left: 35px;
  position: absolute;
  top: 350px;
  max-width: 1000px;
}

.hero_content_wrapper p {
  color: white;
  font-size: 28px;
  line-height: 46px;
}

.hero_content_wrapper h1 {
  color: white;
  line-height: 46px;
}

@media (max-width: 500px) {
  .hero_content h1 {
    font-size: 3rem;
    letter-spacing: 3px;
  }
}

@media (max-width: 1200px) {
  .hero_lower_content {
    margin: 0 10%;
  }
}

@media only screen and (max-width: 1100px) {
  .hero_content_wrapper {
    display: none;
  }
}

@media (max-width: 450px) {
  .hero_titles {
    font-size: 12pt;
  }
}

@media only screen and (min-width: 1920px) {
  #hero_video {
    width: 100%;
  }
}

@media only screen and (min-width: 1100px) {
  .hero-content-show-small {
    display: none;
  }
}
