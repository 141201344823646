.white-text {
  color: white;
}

.blue-text {
  color: #0096c8;
}

.gray-text {
  color: #47575d;
}

.blue-bg {
  background: #0096c8;
}

.green-bg {
  background: #7aa540;
}

.green-heading:after {
  background: #7aa43f;
  content: "";
  display: inline-block;
  width: 70px;
  height: 10px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -35px;
}

.blue-heading:after {
  background: #0096c8;
  content: "";
  display: inline-block;
  width: 70px;
  height: 10px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -35px;
}

.heading-white {
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 2px;
  font-size: 40px;
  line-height: 1.3;
  font-weight: 800;
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.heading-blue {
  color: #0096c8;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 2px;
  font-size: 40px;
  line-height: 1.3;
  font-weight: 800;
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.h-5 {
  font-size: 22px;
  line-height: 38px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 400;
}

.h-2 {
  letter-spacing: 2px;
  font-size: 30px;
  line-height: 37px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.h-4 {
  font-size: 22px;
  line-height: 35px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
}

.h-7 {
  color: #47575d;
  font-size: 28px;
  line-height: 38px;
}

.h-8 {
  font-size: 80px;
  line-height: 100px;
  color: #7aa540;
  font-weight: bold;
}

.sub-heading {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
}

.content-padding {
  padding-left: 3.57143rem !important;
  padding-right: 3.57143rem !important;
  padding-top: 1.42857rem !important;
}

.cust-col-8 {
  padding-right: 15px;
  padding-left: 15px;
}

a {
  text-decoration: none !important;
}

@media only screen and (max-width: 500px) {
  .content-padding {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}
