.route-img {
  width: 100%;
}

.routes-goback {
  text-decoration: none;
  font-weight: 500;
}

.routes-goback:hover {
  cursor: pointer;
}

/* Bryan's profile */
.bryan-route-container {
  /* background: rgb(223, 223, 223); */
  display: flex;
  padding-bottom: 30px;
}

.bryan-route-content-left {
  width: 33%;
  padding: 30px;
}

.bryan-route-content-right {
  padding: 30px 30px;
}

.bryan-route-headerone {
  font-weight: 500;
  font-size: 16pt;
  padding-top: 12px;
  margin-bottom: 2px;
}
.bryan-route-headertwo {
  font-weight: 400;
  font-size: 12pt;
}
.bryan-route-headerthree {
  font-weight: 400;
}

/* Shannah's Profile */
.shannah-route-container {
  /* background: rgb(223, 223, 223); */
  /* height: 100vh; */
  display: flex;
}

/* Chris Route */
.chris-route {
  /* height: 100vh; */
  /* background: rgb(223, 223, 223); */
}

.route-content-container {
  margin: auto;
  margin-top: 130px;
  display: flex;
  justify-content: center;
  max-width: 1200px;
}

@media only screen and (min-width: 767px) {
  .bryan-route-content-right {
    width: 66%;
  }
}

@media only screen and (max-width: 767px) {
  .route-content-container {
    flex-flow: column;
  }
  .bryan-route-content-left {
    width: 50%;
    padding: 30px;
  }
}

@media only screen and (max-width: 550px) {
  .shannah-route-container {
    flex-flow: column;
  }
  .bryan-route-content-left {
    padding: 30px;
    width: 100%;
    margin: auto;
  }
  .bryan-route-content-right {
    padding: 30px;
  }
  .routes-goback {
    display: block;
  }
}

@media only screen and (max-width: 450px) {
  .shannah-route-container {
    flex-flow: column;
  }
  .bryan-route-content-right {
    padding: 30px;
  }
  .routes-goback {
    display: block;
  }
}
