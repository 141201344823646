/* .leadership_main_container {
  background: url(../../../assets/leadership_bg.jpg);
  background-size: cover;
  background-position: center;
}

.faservices_main_container {
  background: url(../../../assets/leadership_bg.jpg);
  background-size: cover;
  background-position: center;
} */

/* top section */
.leadership_main_container {
    padding-top: 60px;
    padding-bottom: 5.71429rem !important;
}

.leadership-top-section-para {
  text-align: center;
  max-width: 1100px;
  margin: auto;
  padding: 0 10px;
}

.leadership-blue-bar-container {
  display: flex;
  justify-content: space-evenly;
  background: #0096c8;
  color: white;
  text-align: center;
  padding: 30px 25px;
  margin-top: 4%;
}

.green-bar-card {
  padding: 5px 10px;
  width: 300px;
}

.leadership-content-right-card-p1 {
  margin-bottom: 0;
  font-size: 26px;
  margin-top: 0;
}

.leadership-content-right-card-p2 {
  margin-top: 0;
  font-size: 26px;
  margin-bottom: 10px;
}

.leadership-content-right-card-p3 {
  margin-top: 5px;
  font-size: 16px;
}

.leadership-icon-image {
  width: 80px;
  height: auto;
}

.card-text-small {
  font-size: 12pt;
}

.leadership_content {
  margin: auto;
  max-width: 790px;
  padding: 5% 0;
}

.leadership_hero_img {
  width: 100%;
}

.team-row {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
}

.team-card {
  /* height: 500px; */
  margin: 0 3px;
  width: 32%;
  border: 1px solid rgb(179 179 179);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  background: white;
}

.team-card a {
  text-decoration: none;
}

.team-card-img-container {
  width: 100%;
  max-height: 200px;
  overflow: hidden;
  background: white;
}

.team-card-img {
  width: 100%;
  height: auto;
}

.team-card-body {
  padding: 0.75rem;
}

/* Team card titles are 16px and the p under in 11pt 8/27/20 */

.team-card-body p {
  font-size: 9pt;
  color: gray;
}

.team-title {
  font-size: 10.5pt;
  font-weight: 600;
  color: black;
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

@media (max-width: 500px) {
  .leadership_main_container h1 {
    font-size: 2rem;
  }
}

/* BIO STYLES */
.bio-container {
  margin: 2% 15%;
}

.bio-breadcrumb {
}

.bio-content-wrapper {
  display: flex;
  margin-top: 10rem;
}

.bio-left-wrapper {
  margin-right: 50px;
}

.bio-left-wrapper p {
  margin: 0;
  font-size: 10pt;
  color: gray;
}

.bio-right-wrapper {
}

/* ===== */
/* Bio Nav Items */
/* ===== */

.di_navbar {
  background-color: #1a1a1a !important;
}

.nav-item-spacing {
  padding: 0 10px;
}

.nav-item-spacing:hover {
  cursor: pointer;
}

.nav-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.nav-buttons {
  width: 95px;
  padding: 4px 20px;
  border: 1px solid gray;
  background: white;
  border-radius: 3px;
  margin: 0 5px;
}

.nav-buttons a {
  text-decoration: none;
  color: white !important;
}

.bio-nav-link {
  color: white;
}

.bio-nav-link:hover {
  text-decoration: none;
  color: white;
}

@media only screen and (max-width: 790px) {
  .leadership_content {
    margin: 5% 2%;
  }
}

@media only screen and (max-width: 630px) {
  .leadership_content {
    margin: 5% 5%;
  }
  .team-row {
    flex-flow: column;
  }
  .team-card {
    width: 100%;
    margin: 7px 3px;
  }
  .team-card-img-container {
    max-height: 430px;
  }
  .leadership-green-bar-container {
    flex-flow: column;
    align-items: center;
  }
}

@media only screen and (max-width: 1400px) {
  .bio-container {
    margin: 2% 5%;
  }
}

@media only screen and (max-width: 1200px) {
  .bio-content-wrapper {
    flex-flow: column;
  }
  .bio-left-wrapper {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 875px) {
  .leadership-blue-bar-container {
    justify-content: center;
    flex-flow: column;
  }
  .green-bar-card {
    padding: 15px 10px;
    width: 100%;
  }
}

@media only screen and (max-width: 550px) {
  .bio-left-wrapper {
    margin-right: 0;
  }
  .bio-left-wrapper img {
    width: 100%;
    height: auto;
  }
  /* .green-bar-card {
    max-width: 400px;
  } */
}
