.nav_clear {
  background-color: rgba(0, 0, 0, 0);
  z-index: 5;
  box-shadow: none !important;
  transition: background-color 0.5s ease 0s;
  -webkit-transition: background-color 0.5s ease 0s;
  -moz-transition: background-color 0.5s ease 0s;
  -ms-transition: background-color 0.5s ease 0s;
  -o-transition: background-color 0.5s ease 0s;
}

.nav_color {
  background-color: #181818;
  z-index: 5;
  transition: background-color 0.5s ease 0s;
  -webkit-transition: background-color 0.5s ease 0s;
  -moz-transition: background-color 0.5s ease 0s;
  -ms-transition: background-color 0.5s ease 0s;
  -o-transition: background-color 0.5s ease 0s;
}

.nav_link_white {
  text-transform: uppercase;
  font-size: 12px;
  color: white;
  transition: background-color 0.5s ease 0s;
  -webkit-transition: background-color 0.5s ease 0s;
  -moz-transition: background-color 0.5s ease 0s;
  -ms-transition: background-color 0.5s ease 0s;
  -o-transition: background-color 0.5s ease 0s;
  padding: 0 5px;
}

.nav_link_white:hover {
  color: #62cff3;
}

.nav_link_blue {
  color: #0096c8;
  transition: background-color 0.5s ease 0s;
  -webkit-transition: background-color 0.5s ease 0s;
  -moz-transition: background-color 0.5s ease 0s;
  -ms-transition: background-color 0.5s ease 0s;
  -o-transition: background-color 0.5s ease 0s;
}

.route-nav-item:hover {
  color: white;
  text-decoration: none;
}

.login-img {
  width: 30px;
  padding-right: 5px;
}

.login-flex {
  display: flex;
  align-items: center;
}

.navbar-expand-lg .navbar-nav {
  align-items: center;
}

@media only screen and (max-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    align-items: start;
  }

  .nav_link_white {
    padding: 5px;
  }
}
