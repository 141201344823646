/* Footer */
.footer-container {
  height: 80px;
  /* border-top: 1px solid rgb(168, 168, 168); */
}

.footer-content-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: black;
  color: white;
}

.footer-content-top-label {
  font-size: 13pt;
}

.footer-content-bottom-label {
  font-size: 11pt;
  color: gray;
  font-style: italic;
}

.footer-privacy-wrapper {
  margin: 0 10px;
  text-align: center;
}

.footer-privacy-wrapper p {
  margin: 0;
}

.footer-content-top-label:hover {
  cursor: pointer;
}

.footer-tou-wrapper {
  margin: 0 10px;
  text-align: center;
}

.footer-tou-wrapper p {
  margin: 0;
}

.footer-tou-wrapper p:hover {
  cursor: pointer;
}

/* The Modal (background) */
.modalhide {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modalshow {
  display: block;
  position: fixed; /* Stay in place */
  z-index: 1031; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.footer-modal-content {
  background-color: #fefefe;
  margin: 7% auto; /* 7% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.modal-close-button {
  color: #aaa;
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  margin: 0;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
/* .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  } */

/* general */
.no-margin-footer {
  margin-top: 0;
  margin-bottom: 0;
}

.margin-left {
  margin-left: 50px;
}

.center-text {
  text-align: center;
}

.right-align-text {
  text-align: right;
}

@media only screen and (max-width: 685px) {
  .footer-content-bottom-label {
    font-size: 8pt;
  }
}

@media only screen and (max-width: 500px) {
  .footer-modal-content {
    margin: 25% auto; /* 7% from the top and centered */
    width: 95%;
  }
}
