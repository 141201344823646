.aboutus-container {
    background-image: url(../../assets/images/partnership_bg.jpg);
    height: 100%;
    background-repeat: no-repeat;
    width: 100%;
    padding: 60px 15px;
}

.about-us-content-wrapper {
    padding-left: 3.57143rem !important;
    padding-right: 3.57143rem !important;
    padding-top: 1.42857rem !important;
}

@media only screen and (max-width: 1230px) {
    .aboutus-container {
        background-size: cover;
    }
}

@media only screen and (min-width: 900px) {
    .about-us-content-wrapper {
        max-width: 75%;
    }
}

@media only screen and (max-width: 550px) {
    .about-us-content-wrapper {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }
}